import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.primary.main};
`;

export const CopyrightArea = styled.div`
  ${({ theme }) => css`
    padding: 24px 15px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: ${theme.colors.primary.onMain};
    font-weight: 700;
    gap: 16px;

    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    text-align: left;

    ${media.lessThan('small')`
      font-size: 10px;
    `}

    ${media.greaterThan('768px')`
      max-width: 600px;
    `}
  `}
`;

export const SystemName = styled.p`
  font-weight: 700;
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    p {
      font-weight: 400;
      a {
        color: ${theme.colors.primary.onMain};
        text-decoration: none;
        font-weight: 700;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: ${theme.colors.primary.onMainHighlight};
          text-decoration: underline;
        }
      }
    }

    ${SystemName} {
      font-weight: 700;
    }
  `}
`;

export const Copyright = styled.div`
  text-align: right;
`;
