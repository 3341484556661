import styled from 'styled-components';

export const Container = styled.section`
  background-color: ${(props) => props.theme.colors.bg.main};
  color: ${({ theme }) => theme.colors.bg.main.onMain};
  object-fit: cover;
`;

export const Content = styled.div`
  margin-top: 24px;
`;

export const WrapperSkeletons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 0 24px;
  gap: 16px;
  margin: 0 auto;
`

export const ImageSkeleton = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto 0;
  aspect-ratio: 1;
  max-width: 600px;
`;

export const SkeletonTitle = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary.main};
  padding-bottom: 16px;
  margin-top: 32px;
`;
