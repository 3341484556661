import styled, { css } from 'styled-components';
import { mediaDown, mediaUp } from '../../styles/mixins';
import { HorizontalScrollBarStylized2 } from '../../styles/scrollbar';
import { transparentize } from 'polished';

export const Wrapper = styled.header`
  background-color: ${({ theme }) => theme.colors.bg.main};
`;

export const Container = styled.header`
  margin: 0 auto;
  max-width: 600px;
  padding: 24px 24px 0;
`;

export const ImageWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 30vw;
    max-width: 110px;
    margin: auto 0;
    overflow: hidden;
    max-height: 100%;
    background-color: ${theme.colors.primary.main};
    border-radius: 50%;
    border: 2px solid ${theme.colors.primary.main};
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  `}
`;

export const Image = styled.div`
  ${({ theme, src }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${src});
  `}
`;
export const WrapperHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 8px;

    ${mediaDown.md`
      gap: 8px;
    `}
  `}
`;
export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const MenuTitle = styled.h1`
  font-size: clamp(14px, 4vw, 20px);
  color: ${({ theme }) => theme.colors.bg.onMain};
  text-transform: uppercase;
  font-family: WorkSans;
  font-weight: 700;
  /* ${mediaDown.md`
      font-size: 14px;
  `} */
`;

export const InfoFranchisee = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  p,
  a {
    width: 100%;
    font-size: 12px;
    color: ${({ theme }) => transparentize(0.5, theme.colors.bg.onMain)};
    text-transform: capitalize;
    font-family: WorkSans;
    font-weight: 400;
    text-decoration: none;
    ${mediaUp.md`
      font-size: 16px;
    `}
  }
  svg{
    width:  clamp(12px, 3.8vw, 16px);
    height:  clamp(12px, 3.8vw, 16px);
    /* color: ${({ theme }) => transparentize(0.5, theme.colors.bg.onMain)}; */
  }
  &:hover {
    p,
    a {
      color: ${({ theme }) => theme.colors.secondary.main};
      font-weight: 500;
    }

    svg {
      color: ${({ theme }) => theme.colors.secondary.main};
    }
  }
`;

export const ScrollCategories = styled.div`
  margin-top: 24px;
  width: 100%;
  padding: 0 0 16px;
  width: 100%;
  overflow-x: scroll;
  position: relative;

  ${mediaDown.md`
      &::-webkit-scrollbar {
        display: none;
      }
      padding-bottom: 0;
      margin: 16px 0 24px;
    `}
  ${HorizontalScrollBarStylized2}
`;
export const CategoryRow = styled.div`
  display: flex;
  overflow-y: visible;
  align-items: center;
  width: auto;
  height: auto;
  gap: 16px;
`;
export const CategoryItem = styled.div`
  ${({ theme, selected }) => css`
    height: 3.5rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: all 200ms ease-in-out;
    justify-content: center;
    padding: 0.6rem 0.8rem 0.5rem;
    border: 1px solid ${transparentize(0.7, theme.colors.bg.onMain)};
    color: ${({ theme }) => transparentize(0.5, theme.colors.bg.onMain)};
    cursor: pointer;
    font-weight: 700;
    gap: 16px;
    white-space: nowrap;

    ${
      selected &&
      css`
        font-weight: 700;
        border-color: ${theme.colors.primary.main};
        color: ${theme.colors.primary.main};
      `
    }

    &:hover {
      border-color:  ${theme.colors.bg.onMain};
      color: ${theme.colors.bg.onMain};
    }
  `}
`;
