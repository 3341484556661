/* eslint import/no-anonymous-default-export: "off" */
export default {
  colors: {
    primary: {
      main: '#18407C',
      onMain: '#fff',
      onMainHighlight: '#F8CC00',
      onMainForeground: '#f2e6da',
    },
    secondary: {
      main: '#ffaf40',
      onMain: '#18407C',
      onMainHighlight: '#18407C',
      onMainForeground: '#f2e6da',
    },
    tertiary: {
      main: '#12593f',
      light: '#b3cc3d',
      onMain: '#fff',
      onLight: '#18407C',
      onMainHighlight: '#ffaf40',
      onMainForeground: '#f2e6da',
      onLightHighlight: '#18407C',
      onLightForeground: '#f2e6da',
    },
    bg: {
      main: '#f2e6da',
      light: '#fff',
      onMain: '#18407C',
      onLight: '#000',
      onMainHighlight: '#18407C',
      onLightHighlight: '#18407C',
    },
  },
  breakpoints: {
    xs: 320,
    xm: 375,
    xxs: 425,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1378,
  },
};
