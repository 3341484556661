import * as S from './styles'

export default function AppLoader() {
  return (
    <S.Wrapper>
      <S.Loader >
        <S.LoaderIcon size={64} color='#000' />
        <S.Text>Carregando...</S.Text>
      </S.Loader>
    </S.Wrapper>
  )
}
