import { css } from 'styled-components';
import theme from './variables';

export const mediaUp = Object.keys(theme.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media screen and (min-width: ${theme.breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);
export const mediaDown = Object.keys(theme.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media screen and (max-width: ${theme.breakpoints[label]}px) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);
