import { lighten } from 'polished';
import { css } from 'styled-components';

export const ScrollBarStylized = css`
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.bg.main};
  }

  &::-webkit-scrollbar {
    width: 0.8rem;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.main};
    border-radius: 0.8rem;
  }
`;

export const ScrollBarStylized2 = css`
  &::-webkit-scrollbar-track {
    background-color: #EAEAEA;
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }

  &::-webkit-scrollbar {
    width: 0.8rem;
    background-color: ${({ theme }) => theme.colors.bg.main};
    border-top-right-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #8F8F8F;
    border-radius: 0.3rem;
  }
`;

export const HorizontalScrollBarStylized = css`
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.bg.main};
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.8rem;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${lighten(0.1, '#8F8F8F')};
    border-radius: 0.8rem;

    &:hover {
      background-color: #8f8f8f;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;

export const HorizontalScrollBarStylized2 = css`
  &::-webkit-scrollbar-track {
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.6rem;
    background-color: #eaeaea;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${lighten(0.1, '#8F8F8F')};
    border-radius: 0.8rem;

    &:hover {
      background-color: #8f8f8f;
    }
    &:active {
      background-color: ${({ theme }) => theme.colors.primary.main};
    }
  }
`;
