import * as S from './styles'

export default function Inlive() {
  return (
    <S.Wrapper>
      <S.Loader >
        <img src="/logo512.png" width={180} height={180} alt="inlive"/>
        <S.Text bold>Franqueado<br/> não encontrado</S.Text>
        <S.Text>É possível que o link esteja incorreto, Tente ler outro QRCode ou procure um atendente</S.Text>
      </S.Loader>
    </S.Wrapper>
  )
}
