import styled, { css } from 'styled-components';
import { mediaUp } from '../../styles/mixins';
import { transparentize } from 'polished';

export const SectionTitle = styled.h2`
  ${({ theme }) => css`
    margin: 0 0 16px;
    font-size: clamp(14px, 4.2vw, 20px);
    width: 100%;
    color: ${theme.colors.bg.onMain};
    text-transform: capitalize;
    border-bottom: 2px solid ${theme.colors.bg.onMain};
    padding-bottom: 0.8rem;
    font-weight: 700;
  `}
`;
export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 600px;
  padding: 0 24px;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  text-align: left;
`;

export const ProductsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
`;

export const ProductItem = styled.li``;

export const ProductMainInfo = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.colors.bg.onMain};
  font-size: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 16px 0 8px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.bg.onMain};
  &:not(:last-of-type) {
    margin-bottom: 5px;

    ${mediaUp.md`
            margin-bottom: 10px;
          `}
  }

  ${mediaUp.xxs`
          font-size: 1.4rem;
        `}

  div {
    flex: 1;
  }
`;

export const CategoryImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const ProductName = styled.span`
  color: ${({ theme }) => transparentize(0.3, theme.colors.bg.onMain)};
  text-transform: lowercase;
  text-transform: capitalize;
  font-size: clamp(12px, 3.8vw, 16px);
  `;
export const ProductPrice = styled.span`
 font-weight: 700;
 color: ${({ theme }) => theme.colors.primary.main};
 font-size: clamp(12px, 3.8vw, 16px);
`;
export const ProductIngredients = styled.span`
 font-size: 1.5rem;
    margin-bottom: 12px;

    ${mediaUp.md`
        font-size: 1.6rem;
      `}
`;
