import { createGlobalStyle } from 'styled-components';
import WorkSansItalic from '../assets/fonts/WorkSans-Italic-VariableFont_wght.ttf';
import WorkSans from '../assets/fonts/WorkSans-VariableFont_wght.ttf';
import Poppins from '../assets/fonts/poppins-v15-latin-regular.woff2'
import PoppinsBold from '../assets/fonts/poppins-v15-latin-700.woff2'
import PoppinsBolder from '../assets/fonts/poppins-v15-latin-900.woff2'
export default createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
    box-sizing: border-box;
  }
  html {
    font-size: 10px;
    box-sizing: border-box;
  }
  body {
    box-sizing: border-box;
    line-height: 1;
    background-color: ${({ theme }) => theme.colors.bg.main};
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  @font-face {
    font-family: WorkSans;
    src: url(${WorkSans});
    font-style: normal;
  }

  @font-face {
    font-family: 'WorkSansItalic';
    src: url(${WorkSansItalic});
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: ${Poppins};
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: ${PoppinsBold};
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: ${PoppinsBolder};
  }

  * {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    box-sizing: border-box;
    -webkit-box-sizing: border-box ;
	-moz-box-sizing: border-box ;
	-ms-box-sizing: border-box ;
  }


`;
