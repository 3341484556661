import React from 'react';
import Category from '../../components/Category';
import { useCategories, useQueryParams } from '../../hooks';
import {
  Container,
  Content,
  ImageSkeleton,
  WrapperSkeletons,
  SkeletonTitle,
} from './styles';
import { useTheme } from 'styled-components';
import Skeleton from 'react-loading-skeleton';

export default function MenuComponent() {
  const codigoFranqueado = useQueryParams('franqueado');
  const {isLoading, categories} = useCategories(codigoFranqueado);
  const theme = useTheme();

  return (
    <>
      <Container>
        {isLoading &&
          [1, 2, 3].map(() => (
            <>
              <WrapperSkeletons>
                <SkeletonTitle>
                  <Skeleton height={32} width={150} />
                </SkeletonTitle>
                <Skeleton height={'100%'} wrapper={ImageSkeleton} />
                <Skeleton height={50} width={'100%'} />
                <Skeleton height={50} width={'100%'} />
                <Skeleton height={50} width={'100%'} />
                <Skeleton height={50} width={'100%'} />
                <Skeleton height={50} width={'100%'} />
                <Skeleton height={50} width={'100%'} />
              </WrapperSkeletons>
            </>
          ))}
        <Content>
          {!isLoading &&
            categories.map((category, index) => (
              <Category
                title={category.descricaoCategoriaProduto}
                urlImagem={category.urlImagem}
                codigoCategoriaProduto={category.codigoCategoriaProduto}
                bgColor={theme.colors[index % 2 === 0 ? 'bg' : 'primary'].main}
                color={theme.colors[index % 2 === 0 ? 'bg' : 'primary'].onMain}
                codigoFranqueado={codigoFranqueado}
                rowReverse={index % 2 === 0 ? '' : 'row__reverse'}
                key={index}
              />
            ))}
        </Content>
      </Container>
    </>
  );
}
