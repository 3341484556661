import React from 'react';
import { useProducts } from '../../hooks';
import {
  SectionTitle,
  Wrapper,
  CategoryWrapper,
  CategoryImage,
  ProductsList,
  ProductItem,
  ProductMainInfo,
  ProductPrice,
  ProductName,
  ProductIngredients,
} from './styles';

export default function Category({
  title,
  urlImagem,
  codigoFranqueado,
  codigoCategoriaProduto,
}) {
  const products = useProducts(codigoFranqueado, codigoCategoriaProduto);

  const capitalizeName = (name) => {
    if (!name) return '';
    return name.toLowerCase()
      .split(' ')
      .map((word) => word[0]?.toUpperCase() + word?.slice(1))
      .join(' ');
  }

  return (
    <Wrapper id={`category_${codigoCategoriaProduto}`}>
      <SectionTitle>{title}</SectionTitle>
      <CategoryWrapper>
        <CategoryImage
          src={urlImagem}
          alt="imagem"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = 'product-placeholder.jpg';
          }}
        />

        <ProductsList>
          {products.length &&
            products.map((product, index) => (
              <ProductItem key={index}>
                <ProductMainInfo>
                  <ProductName className="name">
                    {capitalizeName(product.nomeProduto)}
                  </ProductName>
                  <ProductPrice className="price">
                    {product.precoVenda.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </ProductPrice>
                </ProductMainInfo>

                {product?.ingredientes && (
                  <ProductIngredients className="ingredients">
                    {capitalizeName(product.ingredientes)}
                  </ProductIngredients>
                )}
              </ProductItem>
            ))}
        </ProductsList>
      </CategoryWrapper>
    </Wrapper>
  );
}
