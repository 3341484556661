
import ButtonTopNavigateComponent from '../components/ButtonTopNavigate';
import FooterComponent from '../components/Footer';
import HeaderComponent from '../components/Header';
import GlobalStyle from '../styles/global';
import MenuComponent from './Menu';
import { FranchiseeContextProvider } from '../contexts/FranchiseeContext';

/* eslint import/no-anonymous-default-export: "off"  */
export default () => {
  return (
    <FranchiseeContextProvider  >
      <GlobalStyle />
      <HeaderComponent />
      <MenuComponent />
      <FooterComponent />
      <ButtonTopNavigateComponent />
    </FranchiseeContextProvider>
  );
};
