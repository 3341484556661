import styled, {css} from 'styled-components';
import { LoaderAlt } from '@styled-icons/boxicons-regular'

export const Wrapper = styled.div`
${({theme}) => css`
height: 100vh;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
background-color: ${theme.colors.primary.main};
`}
`;
export const Loader = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: black;
`;

export const Text = styled.h2`
  font-family: 'Work Sans', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: white;
  text-align: center;
  padding: 0 24px;
  line-height: 1.6rem;
  margin: 0;
  ${({bold}) => bold && css`
    font-weight: 700;
  `}
`;

export const LoaderIcon = styled(LoaderAlt)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`
