import * as S from './styles'

export default function FooterComponent() {
  return (
    <S.Footer>
      <S.CopyrightArea>
        <S.LeftContent>
          <S.SystemName>Sistema FTech©</S.SystemName>
          <p>
            Desenvolvido por{' '}
            <a href="https://inlive.com.br/">Inlive Consultoria®</a>
          </p>
        </S.LeftContent>
        <S.Copyright>
          Copyright © {new Date().getFullYear()} - Todos os direitos reservados
        </S.Copyright>
      </S.CopyrightArea>
    </S.Footer>
  );
}
