import { useCategories, useQueryParams } from '../../hooks';
import {
  Container,
  Image,
  ImageWrapper,
  WrapperHeader,
  MenuTitle,
  TextWrapper,
  InfoFranchisee,
  CategoryItem,
  CategoryRow,
  ScrollCategories,
  Wrapper,
} from './styles';
import { useFranchisee } from '../../contexts/FranchiseeContext';
import { useTheme } from 'styled-components';
import { LocationOn } from '@styled-icons/material-rounded';
import { Whatsapp } from '@styled-icons/remix-fill';
import { useState } from 'react';

export default function HeaderComponent() {
  const [buttonSelected, setButtonSelected] = useState()
  const codigoFranqueado = useQueryParams('franqueado');
  const { categories } = useCategories(codigoFranqueado);
  const { franchisee } = useFranchisee();
  const theme = useTheme();

  function handleChangeProduct(value) {
    setButtonSelected(value)
    const elementRef = document.querySelector(`#category_${value}`);

    if (elementRef) {
      elementRef.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function openOnMap(franchisee) {
    const queryParams = [
      franchisee.endereco,
      franchisee.localizacao,
      franchisee.cep.replace(/\.|-|\s+/g, ''),
    ];
    const queryParamsFiltered = queryParams.filter((param) => param !== '');
    const url = `https://maps.google.com/?q=${queryParamsFiltered
      .join()
      .replace(/\s+/g, '')}`;

    window.open(url);
  }

  console.log(franchisee);

  const onlyDigits = (string) => string.replace(/\D/g, '');

  return (
    <Wrapper>
      <Container>
        <WrapperHeader>
          {franchisee?.urlImagemFranquia && (
            <ImageWrapper>
              <Image src={franchisee.urlImagemFranquia} />
            </ImageWrapper>
          )}
          <TextWrapper>
            <MenuTitle>{franchisee?.nomeFranqueado}</MenuTitle>
            {franchisee?.endereco && (
              <InfoFranchisee onClick={() => openOnMap(franchisee)}>
                <LocationOn size={15} color={theme.colors.primary.main} />
                <p>
                  {franchisee?.endereco} - {franchisee?.bairro}&nbsp;
                  <br />
                  {franchisee?.localizacao} - CEP: {franchisee?.cep}
                </p>
              </InfoFranchisee>
            )}
            {franchisee?.celular && (
              <InfoFranchisee>
                <Whatsapp color={theme.colors.primary.main} />
                <a
                  href={`https://api.whatsapp.com/send?phone=55${onlyDigits(
                    franchisee?.celular
                  )}
                  `}
                  target="_blank"
                  rel="noreferrer"
                >
                  {franchisee?.celular}
                </a>
              </InfoFranchisee>
            )}
          </TextWrapper>
        </WrapperHeader>

        <ScrollCategories>
          <CategoryRow>
            {!!categories &&
              categories?.map((category, index) => (
                <CategoryItem
                  selected={buttonSelected === category.codigoCategoriaProduto}
                  key={category.descricaoCategoriaProduto + index}
                  onClick={() =>
                    handleChangeProduct(category.codigoCategoriaProduto)
                  }
                >
                  {category.descricaoCategoriaProduto}
                </CategoryItem>
              ))}
          </CategoryRow>
        </ScrollCategories>
      </Container>
    </Wrapper>
  );
}
