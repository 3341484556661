import { createContext, useContext, useEffect } from 'react';
import { useFranchiseeInfo, useQueryParams,usePersistedState } from '../hooks';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/variables';
import AppLoader from '../components/AppLoader';
import Inlive from '../components/Inlive';
import variables from '../styles/variables';

export function FranchiseeContextProvider({ children }) {
  const codigoFranqueado = useQueryParams('franqueado');
  const franchisee = useFranchiseeInfo(codigoFranqueado);
  const [savedTheme, setSavedTheme] = usePersistedState('app-theme', undefined);

  useEffect(() => {
    if (!franchisee) return;

    setSavedTheme({
      ...theme,
      colors: franchisee.cores,
    });
  }, [franchisee, setSavedTheme]);

  return (
    <FranchiseeContext.Provider value={{ franchisee }}>
      {codigoFranqueado && !!savedTheme?.colors && <ThemeProvider theme={savedTheme}>
        {children}
      </ThemeProvider>}

      {codigoFranqueado && !savedTheme?.colors && <ThemeProvider theme={variables}>
        <AppLoader />
      </ThemeProvider>}

      {
        !codigoFranqueado && <ThemeProvider theme={variables}>
          <Inlive />
        </ThemeProvider>
      }
    </FranchiseeContext.Provider>
  );
}

export const FranchiseeContext = createContext({});

export const useFranchisee = () => useContext(FranchiseeContext);

