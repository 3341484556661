/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from 'react';


export const useQueryParams = (searchParam) => {
  const [param, setParam] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(searchParam);

    setParam(param);
  }, [searchParam]);

  return param;
};

export const useProducts = (codigoFranqueado, codigoCategoriaProduto) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (!codigoFranqueado) return
    const getProductByCategory = async () => {
      const result = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/franqueados/${codigoFranqueado}/categorias/${codigoCategoriaProduto}/produtos`
        )
      ).json();

      setProducts(result);
    };

    getProductByCategory();
  }, [codigoFranqueado, codigoCategoriaProduto]);

  return products;
};

export const useCategories = (codigoFranqueado) => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!codigoFranqueado) return
    const getCategories = async () => {
      setIsLoading(true);
      const result = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/franqueados/${codigoFranqueado}/categorias`
        )
      ).json();

      setCategories(result || []);
      setIsLoading(false);
    };

    getCategories();
  }, [codigoFranqueado]);

  return {
    isLoading,
    categories
  };
};

export const useFranchiseeInfo = (codigoFranqueado) => {
  const [franchiseeInfo, setFranchiseeInfo] = useState();

  useEffect(() => {
    if (!codigoFranqueado) return

    const getFranchiseeInfo = async () => {
      try {
      const result = await (
        await fetch(
          `${process.env.REACT_APP_API_URL}/franqueados?codigoFranqueado=${codigoFranqueado}`
        )
      ).json();

      setFranchiseeInfo(result);
      } catch {
        setFranchiseeInfo(undefined);
      }
    };

    getFranchiseeInfo();
  }, [codigoFranqueado]);

  return franchiseeInfo;
};

export const usePersistedState = (name, defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const nameRef = useRef(name);

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(name);
      if (storedValue !== null) setValue(storedValue);
      else localStorage.setItem(name, defaultValue);
    } catch {
      setValue(defaultValue);
    }
  }, []);

  useEffect(() => {
    try {
      localStorage.setItem(nameRef.current, value);
    } catch {}
  }, [value]);

  useEffect(() => {
    const lastName = nameRef.current;
    if (name !== lastName) {
      try {
        localStorage.setItem(name, value);
        nameRef.current = name;
        localStorage.removeItem(lastName);
      } catch {}
    }
  }, [name]);

  return [value, setValue];
};
