import styled from 'styled-components';

const ButtonTopNavigate = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.onMain};
  border-radius: 50%;
  border: none;
  transition: all 0.25s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  &:before {
    content: '';
    position: absolute;
    top: 18px;
    left: 22px;
    width: 3px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.secondary.onMain};
    transform: rotate(45deg);
  }

  &:after {
    content: '';
    position: absolute;
    top: 18px;
    right: 22px;
    width: 3px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.secondary.onMain};
    transform: rotate(-45deg);
  }
`;

export default ButtonTopNavigate;
